import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { inject, Injectable, Provider } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, Observable, throwError } from "rxjs";

@Injectable()
class ErrorInterceptor implements HttpInterceptor {
	#router = inject(Router);

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((e) => {
				if (!req.url.includes("main-screen")) {
					this.#router.navigate(["glavnaya_stranica"]);
				}
				return throwError(() => e);
			})
		);
	}
}

export const ERROR_PROVIDER: Provider = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: ErrorInterceptor,
		multi: true,
	},
];
