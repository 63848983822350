import {
	HTTP_INTERCEPTORS,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from "@angular/common/http";
import { inject, Injectable, InjectionToken, Provider } from "@angular/core";
import { BehaviorSubject, Observable, shareReplay, tap } from "rxjs";

@Injectable({ providedIn: "root" })
export class LoadingService {
	private requestCount = 0;
	loading$ = new BehaviorSubject<boolean>(false);

	incrementRequestCount() {
		this.requestCount = this.requestCount + 1;
		this.loading$.next(true);
	}

	decrementRequestCount() {
		this.requestCount = this.requestCount - 1;
		if (this.requestCount === 0) {
			this.loading$.next(false);
		}
	}
}

export const LOADING_TOKEN = new InjectionToken<Observable<boolean>>("LOADING_TOKEN", {
	providedIn: "root",
	factory: () => {
		return inject(LoadingService).loading$.pipe(shareReplay(1));
	},
});

@Injectable({ providedIn: "root" })
class LoadingInterceptor implements HttpInterceptor {
	constructor(private _loadingService: LoadingService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this._loadingService.incrementRequestCount();
		return next.handle(req).pipe(
			tap((b) => {
				b instanceof HttpResponse && this._loadingService.decrementRequestCount();
			})
		);
	}
}

export const LOADING_PROVIDER: Provider = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: LoadingInterceptor,
		multi: true,
	},
];
