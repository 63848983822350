import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { API_URL } from "@app";
import { Observable } from "rxjs";
import {
	IHeaderContent,
	IHeaderDetails,
	INew,
	IOlympiadsContent,
	IReqEducationContent,
	IReqNewsContent,
	IReqOlympiadsContent,
	IReqOtherContent,
	IResEducationContent,
	IResMetaEducationPage,
	IResMetaEventsPage,
	IResMetaOlympiadsPage,
	IResNewsContent,
	IResNewsDetails,
	IResOlympiadsContent,
	IResOtherContent,
} from "./pages.interface";

@Injectable({ providedIn: "root" })
export class PagesApiService {
	#http = inject(HttpClient);
	#api = inject(API_URL);

	private get _apiUrl(): string {
		return this.#api + "/content/public";
	}

	public getDetails(id: string): Observable<IHeaderDetails> {
		return this.#http.get<IHeaderDetails>(`${this._apiUrl}/headers/detail/${id}`, {
			params: {
				public: true,
			},
		});
	}

	public getContent(id: string): Observable<IHeaderContent> {
		return this.#http.get<IHeaderContent>(`${this._apiUrl}/content/detail/${id}`, {
			params: {
				public: true,
			},
		});
	}

	// page "Main"
	public getContentMain(): Observable<any> {
		return this.#http.get<any>(`${this._apiUrl}/main-screen`);
	}

	// page "Events"
	public getMeta(): Observable<IResMetaEventsPage> {
		return this.#http.get<IResMetaEventsPage>(`${this._apiUrl}/news/meta`);
	}

	public getNews(data: IReqNewsContent): Observable<IResNewsContent> {
		return this.#http.post<IResNewsContent>(`${this._apiUrl}/news/search`, data);
	}

	public getNewById(id: string): Observable<IResNewsDetails> {
		return this.#http.get<IResNewsDetails>(`${this._apiUrl}/news/detail/${id}`);
	}

	public getRecommendationNews(id: string): Observable<INew[]> {
		return this.#http.get<INew[]>(`${this._apiUrl}/news/recommendation/${id}`, {
			params: {
				take: 3,
			},
		});
	}

	// Education
	public getEducationMeta(): Observable<IResMetaEducationPage> {
		return this.#http.get<IResMetaEducationPage>(`${this._apiUrl}/education/meta`);
	}

	public getEducationContent(data: IReqEducationContent): Observable<IResEducationContent> {
		return this.#http.post<IResEducationContent>(`${this._apiUrl}/education/search`, data);
	}

	// Olympiads
	public getOlympiadsMeta(): Observable<IResMetaOlympiadsPage[]> {
		return this.#http.get<IResMetaOlympiadsPage[]>(`${this._apiUrl}/olympiads/meta`);
	}

	public getOlympiadsContent(data: IReqOlympiadsContent): Observable<IResOlympiadsContent> {
		return this.#http.post<IResOlympiadsContent>(`${this._apiUrl}/olympiads/search`, data);
	}

	public getOlympiadContentById(src: string): Observable<IOlympiadsContent> {
		return this.#http.get<IOlympiadsContent>(`${this._apiUrl}/events/${src}`);
	}

	// Other
	public getOtherPages(data: IReqOtherContent): Observable<IResOtherContent> {
		return this.#http.post<IResOtherContent>(`${this._apiUrl}/headers/content/archived`, data);
	}

	public getOtherPage(name: string, id: string): Observable<IHeaderContent> {
		return this.#http.get<IHeaderContent>(`${this._apiUrl}/headers/content/archived/${name}/${id}`);
	}
}
