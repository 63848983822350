import { Injectable, Provider } from "@angular/core";
import { AbstractTuiDialogService, TUI_DIALOGS } from "@taiga-ui/cdk";
import { DialogOptions } from "./dialog-options";
import { PolymorpheusComponent } from "@tinkoff/ng-polymorpheus";
import { DialogComponent } from "./dialog.component";

@Injectable({ providedIn: "root" })
export class DialogService extends AbstractTuiDialogService<DialogOptions, boolean> {
	readonly defaultOptions = {} as const;
	readonly component = new PolymorpheusComponent(DialogComponent);
}

export const DIALOG_PROVIDER: Provider = {
	provide: TUI_DIALOGS,
	useExisting: DialogService,
	multi: true,
};
