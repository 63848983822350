import { Component, Inject, Self } from "@angular/core";
import { TuiDestroyService, TuiDialog } from "@taiga-ui/cdk";
import { TuiDialogCloseService } from "@taiga-ui/core";
import { POLYMORPHEUS_CONTEXT, PolymorpheusModule } from "@tinkoff/ng-polymorpheus";
import { Observable, takeUntil } from "rxjs";
import { DialogOptions } from "./dialog-options";

@Component({
	standalone: true,
	selector: "app-dialog",
	templateUrl: "dialog.template.html",
	styleUrl: "./dialog.style.less",
	imports: [PolymorpheusModule],
	providers: [TuiDialogCloseService, TuiDestroyService],
})
export class DialogComponent {
	width: number = 400;

	constructor(
		@Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialog<DialogOptions, boolean>,
		@Inject(TuiDialogCloseService) close$: Observable<unknown>,
		@Self() @Inject(TuiDestroyService) destroy$: Observable<unknown>
	) {
		close$.pipe(takeUntil(destroy$)).subscribe(() => this.context.$implicit.complete());
	}

	onClick(response: boolean): void {
		this.context.completeWith(response);
	}
}
