import * as i0 from '@angular/core';
import { Directive, ElementRef, Optional, Self, Inject, Renderer2, NgZone, InjectionToken, Input, NgModule } from '@angular/core';
import { tuiCoerceBooleanProperty } from '@taiga-ui/cdk/coercion';
import * as i1$1 from '@taiga-ui/cdk/services';
import { TuiDestroyService } from '@taiga-ui/cdk/services';
import * as i1 from 'rxjs';
import { race, timer } from 'rxjs';
import { throttleTime, map, skipWhile, take, takeUntil } from 'rxjs/operators';
import { ANIMATION_FRAME, WINDOW } from '@ng-web-apis/common';
import { TUI_FOCUSABLE_ITEM_ACCESSOR, TUI_IS_IOS } from '@taiga-ui/cdk/tokens';
import { tuiCreateToken, tuiProvideOptions } from '@taiga-ui/cdk/utils/miscellaneous';
import { POLLING_TIME } from '@taiga-ui/cdk/constants';
import { tuiPx, tuiIsPresent } from '@taiga-ui/cdk/utils';
class AbstractTuiAutofocusHandler {
  constructor(focusable, el) {
    this.focusable = focusable;
    this.el = el;
  }
  get element() {
    var _a;
    return ((_a = this.focusable) === null || _a === void 0 ? void 0 : _a.nativeFocusableElement) || this.el.nativeElement;
  }
  get isTextFieldElement() {
    return this.element.matches('input, textarea, [contenteditable]');
  }
}
AbstractTuiAutofocusHandler.ɵfac = function AbstractTuiAutofocusHandler_Factory(t) {
  i0.ɵɵinvalidFactory();
};
AbstractTuiAutofocusHandler.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractTuiAutofocusHandler
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiAutofocusHandler, [{
    type: Directive
  }], function () {
    return [{
      type: undefined
    }, {
      type: i0.ElementRef
    }];
  }, null);
})();
const TIMEOUT = 1000;
const NG_ANIMATION_SELECTOR = '.ng-animating';
class TuiDefaultAutofocusHandler extends AbstractTuiAutofocusHandler {
  constructor(focusable, el, animationFrame$) {
    super(focusable, el);
    this.animationFrame$ = animationFrame$;
  }
  setFocus() {
    if (this.isTextFieldElement) {
      race(timer(TIMEOUT), this.animationFrame$.pipe(throttleTime(POLLING_TIME), map(() => this.element.closest(NG_ANIMATION_SELECTOR)), skipWhile(Boolean), take(1))).subscribe(() => this.element.focus({
        preventScroll: true
      }));
    } else {
      this.element.focus({
        preventScroll: true
      });
    }
  }
}
TuiDefaultAutofocusHandler.ɵfac = function TuiDefaultAutofocusHandler_Factory(t) {
  return new (t || TuiDefaultAutofocusHandler)(i0.ɵɵdirectiveInject(TUI_FOCUSABLE_ITEM_ACCESSOR, 10), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(ANIMATION_FRAME));
};
TuiDefaultAutofocusHandler.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDefaultAutofocusHandler,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDefaultAutofocusHandler, [{
    type: Directive
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [TUI_FOCUSABLE_ITEM_ACCESSOR]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [ANIMATION_FRAME]
      }]
    }];
  }, null);
})();
const TEXTFIELD_ATTRS = ['type', 'inputMode', 'autocomplete', 'accept', 'min', 'max', 'step', 'pattern', 'size', 'maxlength'];
class TuiIosAutofocusHandler extends AbstractTuiAutofocusHandler {
  constructor(focusable, el, renderer, zone, win) {
    super(focusable, el);
    this.renderer = renderer;
    this.zone = zone;
    this.win = win;
    this.patchCssStyles();
  }
  setFocus() {
    if (this.isTextFieldElement) {
      this.zone.runOutsideAngular(() => this.iosWebkitAutofocus());
    } else {
      this.element.focus({
        preventScroll: true
      });
    }
  }
  iosWebkitAutofocus() {
    var _a;
    const fakeInput = this.makeFakeInput();
    const duration = this.getDurationTimeBeforeFocus();
    let fakeFocusTimeoutId = 0;
    let elementFocusTimeoutId = 0;
    const blurHandler = () => fakeInput.focus({
      preventScroll: true
    });
    const focusHandler = () => {
      clearTimeout(fakeFocusTimeoutId);
      fakeFocusTimeoutId = this.win.setTimeout(() => {
        clearTimeout(elementFocusTimeoutId);
        fakeInput.removeEventListener('blur', blurHandler);
        fakeInput.removeEventListener('focus', focusHandler);
        elementFocusTimeoutId = this.win.setTimeout(() => {
          this.element.focus({
            preventScroll: false
          });
          fakeInput.remove();
        }, duration);
      });
    };
    fakeInput.addEventListener('blur', blurHandler, {
      once: true
    });
    fakeInput.addEventListener('focus', focusHandler);
    if (this.insideDialog()) {
      this.win.document.body.appendChild(fakeInput);
    } else {
      (_a = this.element.parentElement) === null || _a === void 0 ? void 0 : _a.appendChild(fakeInput);
    }
    fakeInput.focus({
      preventScroll: true
    });
  }
  /**
   * @note:
   * emulate textfield position in layout with cursor
   * before focus to real textfield element
   *
   * required note:
   * [fakeInput.readOnly = true] ~
   * don't use {readOnly: true} value, it's doesn't work for emulate autofill
   *
   * [fakeInput.style.opacity = 0] ~
   * don't use {opacity: 0}, sometimes it's doesn't work for emulate real input
   *
   * [fakeInput.style.fontSize = 16px] ~
   * disable possible auto zoom
   *
   * [fakeInput.style.top/left] ~
   * emulate position cursor before focus to real textfield element
   */
  makeFakeInput() {
    const fakeInput = this.renderer.createElement('input');
    const rect = this.element.getBoundingClientRect();
    this.patchFakeInputFromFocusableElement(fakeInput);
    fakeInput.style.height = tuiPx(rect.height);
    fakeInput.style.width = tuiPx(rect.width / 2);
    fakeInput.style.position = 'fixed';
    fakeInput.style.zIndex = '-99999999';
    fakeInput.style.caretColor = 'transparent';
    fakeInput.style.border = 'none';
    fakeInput.style.outline = 'none';
    fakeInput.style.color = 'transparent';
    fakeInput.style.background = 'transparent';
    fakeInput.style.cursor = 'none';
    fakeInput.style.fontSize = tuiPx(16);
    fakeInput.style.top = tuiPx(rect.top);
    fakeInput.style.left = tuiPx(rect.left);
    return fakeInput;
  }
  getDurationTimeBeforeFocus() {
    return parseFloat(this.win.getComputedStyle(this.element).getPropertyValue('--tui-duration')) || 0;
  }
  /**
   * @note:
   * unfortunately, in older versions of iOS
   * there is a bug that the fake input cursor
   * will move along with the dialog animation
   * and then that dialog will be shaking
   */
  insideDialog() {
    return !!this.element.closest('tui-dialog');
  }
  /**
   * @note:
   * This is necessary so that the viewport isn't recalculated
   * and then the dialogs don't shake.
   *
   * Also, we need to fixed height viewport,
   * so that when focusing the dialogs don't shake
   */
  patchCssStyles() {
    [this.win.document.documentElement, this.win.document.body].forEach(element => {
      element.style.setProperty('overflow', 'auto');
      element.style.setProperty('height', '100%');
    });
  }
  /**
   * @note:
   * inherit basic attributes values from real input
   * for help iOS detect what do you want see on keyboard,
   * for example [inputMode=numeric, autocomplete=cc-number]
   */
  patchFakeInputFromFocusableElement(fakeInput) {
    TEXTFIELD_ATTRS.forEach(attr => {
      const value = this.element.getAttribute(attr);
      if (tuiIsPresent(value)) {
        fakeInput.setAttribute(attr, value);
      }
    });
  }
}
TuiIosAutofocusHandler.ɵfac = function TuiIosAutofocusHandler_Factory(t) {
  return new (t || TuiIosAutofocusHandler)(i0.ɵɵdirectiveInject(TUI_FOCUSABLE_ITEM_ACCESSOR, 10), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(Renderer2), i0.ɵɵdirectiveInject(NgZone), i0.ɵɵdirectiveInject(WINDOW));
};
TuiIosAutofocusHandler.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiIosAutofocusHandler,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiIosAutofocusHandler, [{
    type: Directive
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [TUI_FOCUSABLE_ITEM_ACCESSOR]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.Renderer2,
      decorators: [{
        type: Inject,
        args: [Renderer2]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: Window,
      decorators: [{
        type: Inject,
        args: [WINDOW]
      }]
    }];
  }, null);
})();
const TUI_AUTOFOCUS_DEFAULT_OPTIONS = {
  delay: NaN // NaN = no delay/sync
};
const TUI_AUTOFOCUS_OPTIONS = tuiCreateToken(TUI_AUTOFOCUS_DEFAULT_OPTIONS);
function tuiAutoFocusOptionsProvider(options) {
  return tuiProvideOptions(TUI_AUTOFOCUS_OPTIONS, options, TUI_AUTOFOCUS_DEFAULT_OPTIONS);
}
const TUI_AUTOFOCUS_HANDLER = new InjectionToken('[TUI_AUTOFOCUS_HANDLER]');
const TUI_AUTOFOCUS_PROVIDERS = [{
  provide: TUI_AUTOFOCUS_HANDLER,
  useFactory: (focusable, el, animationFrame$, renderer, zone, win, isIos) => isIos ? new TuiIosAutofocusHandler(focusable, el, renderer, zone, win) : new TuiDefaultAutofocusHandler(focusable, el, animationFrame$),
  deps: [[new Optional(), new Self(), TUI_FOCUSABLE_ITEM_ACCESSOR], ElementRef, ANIMATION_FRAME, Renderer2, NgZone, WINDOW, TUI_IS_IOS]
}, TuiDestroyService];
class TuiAutoFocusDirective {
  constructor(handler, options, destroy$) {
    this.handler = handler;
    this.options = options;
    this.destroy$ = destroy$;
    this.autoFocus = true;
  }
  ngAfterViewInit() {
    if (tuiCoerceBooleanProperty(this.autoFocus)) {
      this.focus();
    }
  }
  focus() {
    if (Number.isNaN(this.options.delay)) {
      void Promise.resolve().then(() => this.handler.setFocus());
    } else {
      timer(this.options.delay).pipe(takeUntil(this.destroy$)).subscribe(() => this.handler.setFocus());
    }
  }
}
TuiAutoFocusDirective.ɵfac = function TuiAutoFocusDirective_Factory(t) {
  return new (t || TuiAutoFocusDirective)(i0.ɵɵdirectiveInject(TUI_AUTOFOCUS_HANDLER), i0.ɵɵdirectiveInject(TUI_AUTOFOCUS_OPTIONS), i0.ɵɵdirectiveInject(TuiDestroyService, 2));
};
TuiAutoFocusDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiAutoFocusDirective,
  selectors: [["", "tuiAutoFocus", ""]],
  inputs: {
    autoFocus: [0, "tuiAutoFocus", "autoFocus"]
  },
  features: [i0.ɵɵProvidersFeature(TUI_AUTOFOCUS_PROVIDERS)]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAutoFocusDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiAutoFocus]',
      providers: TUI_AUTOFOCUS_PROVIDERS
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_AUTOFOCUS_HANDLER]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_AUTOFOCUS_OPTIONS]
      }]
    }, {
      type: i1$1.TuiDestroyService,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }];
  }, {
    autoFocus: [{
      type: Input,
      args: ['tuiAutoFocus']
    }]
  });
})();
class TuiAutoFocusModule {}
TuiAutoFocusModule.ɵfac = function TuiAutoFocusModule_Factory(t) {
  return new (t || TuiAutoFocusModule)();
};
TuiAutoFocusModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiAutoFocusModule
});
TuiAutoFocusModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAutoFocusModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiAutoFocusDirective],
      exports: [TuiAutoFocusDirective]
    }]
  }], null, null);
})();
class TuiSynchronousAutofocusHandler extends AbstractTuiAutofocusHandler {
  constructor(focusable, el) {
    super(focusable, el);
  }
  setFocus() {
    this.element.focus({
      preventScroll: true
    });
  }
}
TuiSynchronousAutofocusHandler.ɵfac = function TuiSynchronousAutofocusHandler_Factory(t) {
  return new (t || TuiSynchronousAutofocusHandler)(i0.ɵɵdirectiveInject(TUI_FOCUSABLE_ITEM_ACCESSOR, 10), i0.ɵɵdirectiveInject(ElementRef));
};
TuiSynchronousAutofocusHandler.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiSynchronousAutofocusHandler,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSynchronousAutofocusHandler, [{
    type: Directive
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [TUI_FOCUSABLE_ITEM_ACCESSOR]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractTuiAutofocusHandler, TUI_AUTOFOCUS_DEFAULT_OPTIONS, TUI_AUTOFOCUS_HANDLER, TUI_AUTOFOCUS_OPTIONS, TUI_AUTOFOCUS_PROVIDERS, TuiAutoFocusDirective, TuiAutoFocusModule, TuiDefaultAutofocusHandler, TuiIosAutofocusHandler, TuiSynchronousAutofocusHandler, tuiAutoFocusOptionsProvider };
