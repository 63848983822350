import { Pipe } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({
	standalone: true,
	name: "dateFormat",
})
export class DateFormatPipe {
	transform(value: string): string {
		const date = DateTime.fromISO(value);
		if (date.isValid) {
			return date.toFormat("dd.MM.yyyy");
		} else {
			return "";
		}
	}
}
