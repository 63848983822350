import { BreakpointObserver } from "@angular/cdk/layout";
import { inject, InjectionToken } from "@angular/core";
import { map, Observable, shareReplay } from "rxjs";

export const BREAKPOINTS = new InjectionToken<Observable<"mobile" | "tablet" | "desktop">>(
	"(min-width: 768px) (min-width: 1440px)",
	{
		providedIn: "root",
		factory: () => {
			return inject(BreakpointObserver)
				.observe(["(min-width: 768px)", "(min-width: 1440px)"])
				.pipe(
					map((res) => {
						if (!res.breakpoints["(min-width: 768px)"]) return "mobile";
						if (!res.breakpoints["(min-width: 1440px)"]) return "tablet";
						return "desktop";
					}),
					shareReplay(1)
				);
		},
	}
);
