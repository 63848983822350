export interface IHeader {
	id: number;
	name: string;
	createdAt: string;
}

export interface IHeaderDetail {
	id: number;
	name: string;
	src?: string;
}

export interface IHeaderDetails {
	name: string;
	contentItems: IHeaderDetail[];
}

export interface IHeaderContent {
	id: number;
	name: string;
	type: string;
	content: any[];
	public: boolean;
	headlinesId: number;
	deletedAt: string | null;
	createdAt: string;
}

export interface IDirection {
	id: number;
	name: string;
	color: string;
}

export interface ITag {
	id: number;
	name: string;
}

export interface IResMetaEventsPage {
	direction: IDirection[];
	tags: ITag[];
}

export interface IReqNewsContent {
	query: string;
	direction: number[];
	tags: number[];
	startDate?: string;
	endDate?: string;
	take: number;
	skip: number;
}

export interface INew {
	id: number;
	title: string;
	cover: string;
	createdAt: string;
	pinnedAt: string;
	tags: ITag[];
	directions: IDirection[];
	src: string;
}

export interface IResNewsContent {
	rows: INew[];
	infoPage: {
		totalCount: number;
	};
}

export interface IResNewsDetails {
	id: number;
	title: string;
	content: string;
	contentParse: any[];
	cover: string;
	createdAt: string;
	tags: ITag[];
	directions: IDirection[];
}

export interface INavs {
	id: string;
	label: string;
	src: string;
	_id: number;
	children?: boolean;
}

export interface IInnerNav {
	index: number;
	label: string;
	content: any[];
}

export interface IResMetaEducationPage {
	filters: { [key: string]: string };
	menu: IHeaderDetail[];
}

export enum ELearningTypeRu {
	PROFILE_SHIFT = "Профильные смены",
	REGULAR_SHIFT = "Регулярные программы",
	REMOTE_PROGRAM = "Дистанционные программы",
	FOR_TEACHERS = "Педагогам",
}

export interface IReqEducationContent {
	filter?: string;
	menuItemId: number;
	sortDate: "asc" | "desc";
	skip: number;
	take: number;
}

export interface IEducationContent {
	archive: boolean;
	buttonRequest: boolean;
	content: any;
	contentItemId: number;
	cover: string;
	createdAt: string;
	deletedAt: string | null;
	endDate: string;
	eventId: number | null;
	forWhom: string;
	id: number;
	name: string;
	public: boolean;
	startDate: string;
	typeEducation: string;
	typeEventsPublic: string;
	src: string;
	endDateForApplications: string;
}

export interface IResEducationContent {
	rows: IEducationContent[];
	infoPage: {
		totalCount: number;
	};
}

export interface IResMetaOlympiadsPage {
	id: number;
	name: string;
	src: string;
}

export interface IReqOlympiadsContent {
	menuItemId: number;
	take: number;
	skip: number;
}

export interface IOlympiadsContent {
	id: number;
	name: string;
	cover: string;
	content: any[];
	contentItemId: number;
	typeEventsPublic: string;
	forWhom: string;
	public: boolean;
	buttonRequest: boolean;
	archive: boolean;
	startDate: string;
	endDate: string;
	eventId: number;
	createdAt: string;
	deletedAt: string | null;
	applicationLink: string;
	src: string;
}

export interface IResOlympiadsContent {
	rows: IOlympiadsContent[];
	infoPage: {
		totalCount: number;
	};
}

export interface IReqContentById {
	eventsPublicContentId: number;
	take: number;
	skip: number;
}

export interface IReqOtherContent {
	take: number;
	skip: number;
}

export interface IOtherContent {
	id: number;
	name: string;
	src: string;
	relation: "EventsPublicContent" | "ContentItems";
}

export interface IResOtherContent {
	rows: IOtherContent[];
	infoPage: {
		totalCount: number;
	};
}
