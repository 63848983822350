import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { catchError, EMPTY, forkJoin, map, of, switchMap } from "rxjs";
import { BreadcrumbsService } from "../breadcrumbs";
import { PagesApiService } from "./pages.api.service";

export const pageDetailsByIdResolve = (route: ActivatedRouteSnapshot, id: string) => {
	const pagesApiService = inject(PagesApiService);
	return pagesApiService.getDetails(id).pipe(switchMap((res) => of(res)));
};

// Revolution solution: resolvers for each page
// Page - Главная страница
export const mainPageContentResolver = (route: ActivatedRouteSnapshot) => {
	const pagesApiService = inject(PagesApiService);
	return pagesApiService.getContentMain();
};

// Page - Сведения об ОО
export const informationPageDetailsResolve = (route: ActivatedRouteSnapshot, id: string) => {
	const pagesApiService = inject(PagesApiService);
	return pagesApiService.getDetails(id).pipe(
		switchMap((res) =>
			of(res).pipe(
				map((res) => {
					res.contentItems.push({ id: 0, name: "Обратная связь", src: "feedback" });
					return res;
				})
			)
		)
	);
};

export const informationPageContentResolver = (route: ActivatedRouteSnapshot, id: string) => {
	const pagesApiService = inject(PagesApiService);
	return pagesApiService.getContent(id).pipe(switchMap((res) => of(res)));
};

// Page - События
export const eventsPageContentResolver = (route: ActivatedRouteSnapshot) => {
	const pagesApiService = inject(PagesApiService);
	return pagesApiService.getMeta().pipe(switchMap((res) => of(res)));
};

export const eventDetailsPageContentResolver = (route: ActivatedRouteSnapshot) => {
	const pagesApiService = inject(PagesApiService);
	const id = route.params["id"];
	const news$ = pagesApiService.getNewById(id).pipe(
		switchMap((res) => {
			return pagesApiService.getRecommendationNews(String(res.id));
		})
	);
	return forkJoin({
		news: news$,
		details: pagesApiService.getNewById(id),
	}).pipe(switchMap((res) => of(res)));
};

// Page - Проекты
export const projectsPageDetailsResolve = (route: ActivatedRouteSnapshot) => {
	const id = "proekty"; // mock id
	const pagesApiService = inject(PagesApiService);
	return pagesApiService.getDetails(id).pipe(switchMap((res) => of(res)));
};

// Page - Обучение + Олимпиады
export const eventByIdContentResolver = (route: ActivatedRouteSnapshot) => {
	const pagesApiService = inject(PagesApiService);
	const breadcrumbService = inject(BreadcrumbsService);
	const id = route.params["id"];
	return pagesApiService.getOlympiadContentById(id).pipe(
		switchMap((res) =>
			of(res).pipe(
				map((res) => {
					if (breadcrumbService.breadcrumbs$.value[2]) {
						breadcrumbService.removeBreadcrumbsInterval(2, 2);
					}
					breadcrumbService.addBreadcrumb({
						id: res.id,
						name: res.name,
						src: String(res.src),
					});

					return res;
				})
			)
		)
	);
};

// Page - Обучение
export const educationPageDetailsResolve = (route: ActivatedRouteSnapshot) => {
	const pagesApiService = inject(PagesApiService);
	return pagesApiService.getEducationMeta().pipe(switchMap((res) => of({ contentItems: res.menu })));
};

// Page - Олимпиады
export const olympiadsPageDetailsResolve = (route: ActivatedRouteSnapshot) => {
	const pagesApiService = inject(PagesApiService);
	return pagesApiService.getOlympiadsMeta().pipe(switchMap((res) => of({ contentItems: res })));
};

// Page - Прочее
export const otherPageDetailsResolve = (route: ActivatedRouteSnapshot) => {
	const pagesApiService = inject(PagesApiService);
	const router = inject(Router);
	const specialId: string | null = route.params["id"];
	if (!specialId) {
		router.navigate(["other"]);
		return EMPTY;
	}

	const [name, id] = specialId.split("-");

	if (!name || !id) {
		router.navigate(["other"]);
		return EMPTY;
	}

	return pagesApiService.getOtherPage(name, id).pipe(
		catchError(() => {
			router.navigate(["other"]);
			return EMPTY;
		})
	);
};
